import React from "react";
import Accordion from "./Accordion.jsx";

const FAQ = () => {
  return (
    <div id="FAQ" className="bg-whiteprimary md:py-14 py-6 md:px-36 px-4">
      <Accordion />
    </div>
  );
};

export default FAQ;
