import React from "react";
import styles from "../../../pages/styles";
import { textlogo } from "../../../assets/images";
import { Helmet } from "react-helmet";

const FreeTrial = () => {
  return (
    <div className="w-full pt-4 pb-6 md:px-40 justify-center content-center">
      <div className="flex flex-col">
        <div className="flex justify-center">
          <img src={textlogo} alt="Tech Tails" className="w-52 mb-6 -ml-5" />
        </div>
        <h2 className={`${styles.biggerheadingdark} text-center`}>
          Request a Free Trial Class Today!
        </h2>
        <p className={`${styles.paragraph} text-center px-2`}>
          We're offering a complimentary 1-hour session with our instructor – no
          sales, no pressure, just a fun and free class for your child. Feel
          free to schedule a session right here
        </p>
      </div>
    </div>
  );
};

export default FreeTrial;
