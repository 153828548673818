import React, { useState } from "react";
import styles from "../../pages/styles";
import { FreeTrialPage } from "../../pages";

const FreeTrialPrograms = ({ title, desc }) => {
  const pricingData = [
    {
      title: "Private Classes",
      type: "Personalized",
      price: "Starting at $240/month",
      description:
        "Experience the ultimate in customized education with our personalized private classes. Designed around your child’s specific interests and needs, these sessions provide a highly customized learning path.",
    },
    {
      title: "Group Classes",
      type: "Course",
      price: "Starting at $135/month",
      description:
        "Join 2-4 other friends or family in a group learning where your child can explore new horizons alongside peers. Our group classes foster teamwork and inspire collective creativity, making learning a shared adventure",
    },
  ];

  return (
    <div className="flex flex-col items-center py-10 md:px-16 lg:px-36 px-4 gap-2">
      <h2 className={`${styles.biggerheadingdark}`}>Programs We Offer</h2>
      <p className={`${styles.paragraph} text-center mb-5`}>
        We are flexible on classes we offer, we're dedicated to teaching these complex topics in a personalized way.
      </p>
      <div className="flex flex-col md:flex-row md:gap-5 gap-5">
        {pricingData.map((pricing, index) => (
          <div
            key={index}
            className="flex flex-col justify-between bg-white rounded-lg shadow-md p-4 md:p-5 w-full md:w-1/2 mx-auto gap-5"
          >
            <div>
              <h2 className={`${styles.headingdark} text-center`}>
                {pricing.title}
              </h2>
              <p
                className={`${styles.paragraph} text-center !font-bold text-gray-500 m-2`}
              >
                {pricing.type}
              </p>
              <p className={`${styles.paragraph} text-center`}>
                {pricing.description}
              </p>
            </div>
            <div className="">
              <h2 className={`${styles.paragraph} text-center text-gray-500`}>
                {pricing.price}
              </h2>
            </div>
          </div>
        ))}
      </div>
      <a
        href="/pages/freetrial"
        className={`${styles.paragraphwhite} bg-darkprimary md:w-fit w-full text-center text-white px-8 py-2.5 rounded-3xl mt-4 block mx-auto focus:border-pinkprimary focus:border-4 hover:bg-orangeprimary`}
      >
        Request Trial Class
      </a>
    </div>
  );
};

export default FreeTrialPrograms;
